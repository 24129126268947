<template>
    <b-modal id="modal" size="lg" :title="data.name"
             @close="onReset()"
             no-close-on-esc
             no-close-on-backdrop
             >
            <file-manager></file-manager>
            <b-form id="form1" @reset="onReset" @submit="onSubmit" v-if="data.id" autocomplete="off">

                <b-row>
                    <div class="form-group col-12">
                        <label>Url</label>
                        <b-input-group>
                            <b-form-input v-model="data.url" readonly></b-form-input>
                            <b-input-group-append is-text>
                                <small class="mr-2">Vygenerovat nové url z názvu?</small>
                                <b-form-checkbox switch class="mr-n2" v-model="data.regenerate_url">
                                    <span class="sr-only">Vygenerovat nové url z názvu?</span>
                                </b-form-checkbox>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    
                    <div class="col-12 form-group">
                        <label>Název</label>
                        <b-tabs content-class="mb-2" :class="{ 'c-tabs-lang--single-lang' : languages.length === 1 }">
                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name" >
                                <b-form-input v-model="data['name_'+lang.name]"
                                              :key="'name_'+lang.name.id"
                                              :class="{ 'border-top-0' : languages.length > 1 }"
                                ></b-form-input>
                            </b-tab>
                        </b-tabs>
                    </div>
                    
                    <div class="col-12 form-group">
                        <label>Titulek (Seo)</label>
                        <b-tabs content-class="mb-2" :class="{ 'c-tabs-lang--single-lang' : languages.length === 1 }">
                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name" >
                                <b-form-input v-model="data['seo_title_'+lang.name]"
                                              :key="'seo_title_'+lang.name.id"
                                              :class="{ 'border-top-0' : languages.length > 1 }"
                                ></b-form-input>
                            </b-tab>
                        </b-tabs>
                    </div>
    
                    <div class="col-12 form-group">
                        <label>Popis (Seo)</label>
                        <b-tabs content-class="mb-2" :class="{ 'c-tabs-lang--single-lang' : languages.length === 1 }">
                            <b-tab v-for="lang in languages" :key="lang.id" :title="lang.name" >
                                <b-form-input v-model="data['seo_description_'+lang.name]"
                                              :key="'seo_description_'+lang.name.id"
                                              :class="{ 'border-top-0' : languages.length > 1 }"
                                ></b-form-input>
                            </b-tab>
                        </b-tabs>
                    </div>
                    
                    <hr class="w-100">
                    <div class="form-group col-12" >
                        <label>Obsah</label>
                    </div>
                    <hr class="w-100">

                    <div class="form-group col-12">
                        <label>Local-file-manager</label>
                        <local-file-manager
                            :origin-table-name="'test'"
                            :id-name-foreign-table="'id_test'"
                            :id-foreign-table-row="data.id"
                        ></local-file-manager>
                    </div>

                    <div class="form-group col-12">
                        <label>image-upload</label>
                        <image-controller
                            :id="id"
                            table-name="test"
                            table-col-name="img"
                            dir-path="upload/test_img"
                        ></image-controller>
                    </div>

                    <div class="form-group col-12">
                        <label>Images-upload</label>
                        <images-controller
                            name-foreign-table="test"
                            :id-foreign-table="data.id"
                        ></images-controller>
                    </div>
                    <hr class="w-100">
                    <div class="form-group col-12">
                        <label>file-upload</label>
                        <file-controller
                            :id="id"
                            :file-url="data.file"
                            table-name="test"
                            table-col-name="file"
                            dir-path="upload/test_file"
                            accept=""
                        ></file-controller>
                    </div>
                    <hr class="w-100">
                    <div class="form-group col-12">
                        <b-form-checkbox v-model="data.visible"
                                         name="check-button"
                                         value="1"
                                         switch>
                            <b>Viditelnost</b>
                        </b-form-checkbox>
                    </div>
                </b-row>
                <b-form-group class="text-right">
                    <b-button variant="danger"  @click="deleteItem(data.id)">Smazat
                        <b-icon icon="trash-fill" ></b-icon>
                    </b-button>
                </b-form-group>
            </b-form>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                        Zavřít
                    </b-button>
                    <b-button type="submit" class="float-right" form="form1" variant="primary">
                        Uložit
                    </b-button>
                </div>
            </template>
    </b-modal>
</template>

<script>
    import FileManager from "../../components/file-manager";
    import LocalFileManager from "../../components/local-file-manager";
    import ImageController from "../../components/image-controller";
    import FileController from "../../components/file-controller";
    import ImagesController from "../../components/images-controller";
    export default {
        name: "test-edit",
        components: {ImagesController, FileController, ImageController, LocalFileManager, FileManager},
        data() {
            return {
                id: parseInt(this.$route.params.id),
                data: {},
            }
        },
        methods: {

            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/test/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        params: {'id': this.id },
                        method: 'GET'
                    });

                    this.data = response.data[0];

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
            async onSubmit(e) {
                e.preventDefault();
                await this.$refs.contentEditor.updateContent();
                try {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/test/update-item.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        data: this.data,
                        method: 'PUT'
                    });

                    this.$emit('loadData');
                    this.$bvModal.hide('modal');
                    this.$router.go(-1);
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
            async deleteItem(id) {
                try {
                    if (confirm("Opravdu smazat?")) {
                        await this.$http({
                            url: process.env.VUE_APP_SERVER + 'api/test/remove-item.php',
                            headers: {'content-type': 'application/x-www-form-urlencoded'},
                            data: {'id': id},
                            method: 'DELETE'
                        });
                        this.$emit('loadData');
                        this.$bvModal.hide('modal');
                        this.$router.go(-1);
                        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                    }

                } catch (error) {
                    this.loadData();
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
            onReset () {
                this.$emit('loadData');
                this.$router.go(-1);
            }

        },
        computed: {
            permissionsOptions: function () {
                return this.$store.getters.getUserRoles;
            },
            languages() {
                return this.$store.getters.langs
            },
        },
        mounted() {
            this.$bvModal.show('modal');
            this.loadData();
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">

</style>
