<template>
    <div class="c-file-manager">
        <b-button class="c-file-manager__open-btn" v-b-toggle.file-manager variant="primary">
            <b-icon icon="files"></b-icon>
            <span>Souborový manažer</span>
        </b-button>
        <b-sidebar id="file-manager" title="Souborový manažer" width="375px" right shadow >
            <div class="px-3 py-2">
                <div class="c-file-manager__upload-btn" title="Přidat soubor">
                    <form class="c-file-upload" ref="uploadform">
                        <label class="c-file-upload__label" :for="'file_manager'" >
                            <input type="file"
                                   :id="'file_manager'"
                                   ref="file"
                                   @change="changeFileName()"
                                   multiple="multiple">
                        </label>

                        <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>
                    </form>
                    <b-icon class="c-image-upload__add"
                            icon="plus-circle-fill"
                            variant="primary"
                    ></b-icon>
                </div>
                <ul class="c-file-manager__list">
                    <li v-for="item in data.records" :key="item.id">
                        <div class="c-file-manager__li-child">
                            <i class="c-file-manager__ico">
                                <b-icon v-if="item.type === 'audio/mpeg'"
                                        icon="file-earmark-music"
                                        variant="primary"></b-icon>

                                <b-icon v-else-if="item.type === 'video/mp4' || item.type === 'video/quicktime'"
                                        icon="file-earmark-play"
                                        variant="primary"></b-icon>

                                <b-icon v-else-if="item.type === 'application/zip' || item.type === 'application/x-rar-compressed' || item.type === 'application/x-rar'"
                                        icon="file-earmark-zip"
                                        variant="primary" ></b-icon>

                                <b-icon v-else-if="item.type === 'application/pdf'"
                                        icon="file-earmark-richtext"
                                        variant="primary" ></b-icon>

                                <img v-else-if="item.type === 'image/jpeg' ||
                                            item.type === 'image/png' ||
                                            item.type === 'image/gif' "
                                     :src="path + item.url" />

                                <b-icon v-else-if="item.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                    item.type === 'application/vnd.ms-excel'"
                                        icon="file-earmark-spreadsheet"
                                        variant="primary"></b-icon>

                                <b-icon v-else-if="item.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
                                                    item.type === 'application/vnd.ms-powerpoint'"
                                        icon="file-earmark-easel"
                                        variant="primary"></b-icon>

                                <b-icon v-else
                                        icon="file-earmark-text"
                                        variant="primary"></b-icon>
                            </i>
                            <a :title="item.name" >{{item.name}}</a>
                            <b-button variant="link"
                                      v-b-popover.focus="'Odkaz byl zkopírován do schránky'"
                                      v-clipboard:copy="path + item.url">
                                <b-icon icon="link"
                                        variant="primary"></b-icon>
                            </b-button>
                            <b-button variant="link" @click="deleteFile(item.id)">
                                <b-icon icon="trash"
                                        variant="primary"></b-icon>
                            </b-button>
                        </div>
                    </li>
                </ul>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    export default {
        name: "file-manager",
        data(){
            return {
                path: process.env.VUE_APP_SERVER,
                file: [],
                data: [],
                uploadPercentage: 0,
                uploading: false
            }
        },
        methods: {
            changeFileName() {

                this.file = this.$refs.file.files;
                this.uploadFile();
            },

            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/file-manager/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'GET'
                    });

                    this.data = response.data;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },

            async uploadFile() {
                try {
                    this.uploading = true;

                    let formData = new FormData();

                    for( var i = 0; i < this.$refs.file.files.length; i++ ){
                        let file = this.$refs.file.files[i];
                        formData.append('file[' + i + ']', file);
                    }

                    await this.$http.post( process.env.VUE_APP_SERVER + 'api/file-manager/add-file.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        }
                    );

                    await this.loadData();
                    this.file = [];
                    this.$refs.uploadform.reset();
                    this.uploading = false;
                    this.uploadPercentage = 0;

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                    this.file = [];
                    this.$refs.uploadform.reset();
                    this.uploading = false;
                }
            },
            async deleteFile(id) {
                try {
                    if (confirm("Opravdu smazat?")) {
                        await this.$http({
                            url: process.env.VUE_APP_SERVER + 'api/file-manager/remove-item.php',
                            headers: {'content-type': 'application/x-www-form-urlencoded'},
                            data: {'id': id},
                            method: 'DELETE'
                        });
                        await this.loadData();
                        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                    }

                } catch (error) {
                    await this.loadData();
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }

            },
        },
        mounted() {
            this.loadData();
        },
    }
</script>

<style lang="scss" scoped>

</style>
