<template>
    <div class="c-file-controller">
        <div v-if="!data.file" class="c-file-controller__add">
            <div class="c-file-upload">
                <label class="c-file-upload__label" :for="'file_'+uniqId" >
                    <input type="file"
                       :id="'file_'+uniqId"
                       :ref="'file_'+uniqId"
                       :accept="accept"
                       @change="changeFileName()">
                </label>

                <b-progress v-if="uploading" class="c-file-upload__progress" :value="uploadPercentage" :max="'100'" animated></b-progress>

            </div>


            <b-input-group class="c-file-controller__input-group">
                <b-input-group-prepend>
                    <b-button variant="outline-primary">
                        <b-icon class="mr-1"
                                icon="file-earmark-plus-fill"
                                variant="primary"></b-icon>
                        Vyber soubor
                    </b-button>
                </b-input-group-prepend>
                <b-form-input type="text"></b-form-input>
            </b-input-group>

        </div>
        <div v-else class="c-file-controller__file">
            <a :href="path + data.file" download target="_blank">
                <b-icon class="mr-1"
                  icon="file-earmark"
                  variant="primary"></b-icon>
                Formulář
            </a>
            <b-button
                    class="c-file-controller__delete-btn" size="sm" variant="danger"
                    @click="removeImage()">
                <b-icon icon="trash"
                        variant="white"
                ></b-icon>
            </b-button>
        </div>
    </div>
</template>

<script>

    export default {
        name: "file-controller",
        props: {
            id:             Number,
            fileUrl:        String,
            tableName:      String,
            dirPath:        String,
            tableColName:   String,
            accept:         { type: String, default: '*' }

        },
        data(){
            return {
                file: [],
                data: { file : null },
                uploadPercentage: 0,
                uploading: false,
                uniqId: null,
                path: process.env.VUE_APP_SERVER
            }
        },
        methods: {
            changeFileName() {

                this.file = this.$refs['file_'+this.uniqId].files;
                this.uploadFile();
            },
            async uploadFile() {
                try {
                    this.uploading = true;

                    let formData = new FormData();

                    for( var i = 0; i < this.$refs['file_'+this.uniqId].files.length; i++ ){
                        let file = this.$refs['file_'+this.uniqId].files[i];
                        formData.append('file[' + i + ']', file);
                    }

                    formData.append('id', this.id);

                    const response =  await this.$http.post( process.env.VUE_APP_SERVER + 'api/articles/add-file.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        }
                    );

                    this.data = response.data;
                    this.file = [];
                    this.uploading = false;
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.file_uploaded'));

                } catch (error) {
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                    this.file = [];
                    this.uploading = false;
                }
            },
            async removeImage() {
                try {
                    if (confirm("Opravdu smazat?")) {
                        await this.$http({
                            url: process.env.VUE_APP_SERVER + 'api/articles/remove-file.php',
                            headers: {'content-type': 'application/x-www-form-urlencoded'},
                            data: {
                                'id': this.id,
                                'table_name': this.tableName,
                                'table_col_name': this.tableColName
                            },
                            method: 'DELETE'
                        });
                        this.data = [];
                        this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.deleted'));
                    }
                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            },
        },
        mounted() {
            this.data['file'] = this.fileUrl;
            this.uniqId = this._uid
        },
    }
</script>

<style lang="scss" scoped>

</style>
